import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { apiUrl } from "@/variables";

export default new Vuex.Store({
  state: {
    lang: "de",
    form: {},
    apiUrl: apiUrl,
  },
  mutations: {
    updateField(state, [field, value, namespace, items, index]) {
      if (namespace && items) {
        state.form[namespace].items[items][index][field] = value;
      } else {
        state.form[field] = value;
      }
    },
    addItem(state, [namespace, items, skeleton]) {
      state.form[namespace].items[items].push(skeleton);
    },
    removeItem(state, [namespace, items]) {
      state.form[namespace].items[items].pop();
    },
    updateLanguage(state, lang) {
      state.lang = lang;
    },
  },
});
