<template>
  <section aria-label="object-location">
    <div class="table-grid row">
      <div class="col">
        <img
            v-if="data.icon.fullpath"
            :src="apiUrl + data.icon.fullpath"
            width="30"
        />

      </div>
      <div class="col" v-html="data.name"/>
      <div class="col">
        <div>
          <a style="text-decoration: none" target="_blank" :href="data.link.path">
            <v-icon> mdi-information</v-icon>
          </a>
          <span v-if="item.metadata[0].value === 'open'">
          <span
              v-for="(opentime, i) in data.opentimes"
              :key="i"
              class="green-text"
          >{{ opentime.open }} - {{ opentime.close }}
                                </span>
            </span>
          <span style="color: red" v-else>Geschlossen</span>
          <br/>

          <v-icon> mdi-phone</v-icon>
          <a v-if="$vuetify.breakpoint.name === 'xs'" :href="'tel:' + data.telephone" class="no-wrap">{{
              data.telephone
            }}</a>
          <span :style="data.telephone.length >= 12 ? 'font-size:12px' : ''" v-else class="no-wrap">{{ data.telephone }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import pageLoaded_child from '@/mixins/pageLoaded/child';
import {apiUrl} from '@/variables';

export default {
  name: 'object_Course',
  mixins: [pageLoaded_child],
  data: () => ({
    apiUrl: apiUrl,
  }),
};
</script>

<style lang="css">
span.no-wrap {
  white-space: nowrap;
}
</style>
