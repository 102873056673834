<template>
  <section aria-label="object_map">
    <v-row no-gutters class="table-grid">
      <v-col>
        <img
            v-if="data.icon"
            :src="apiUrl + data.icon.fullpath"
            width="30"
        />
      </v-col>
      <v-col v-if="stepsMade === 0">
        {{ data.name }}
      </v-col>
      <v-col v-else>
        Wetter
      </v-col>
      <v-col>
        <v-layout
            style="margin: 0 !important"
            row
            align-center

            class="my-10"
        >
          <v-img
              max-width="20"
              class="mr-1"
              v-if="icon"
              :src="
                            'https://openweathermap.org/img/wn/' + icon + '.png'
                        "
          />
          <span class="temp">{{
              temperature ? temperature + ' °C' : 'N/A'
            }}</span>
        </v-layout>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import pageLoaded_child from '@/mixins/pageLoaded/child';
import axios from 'axios';
import {apiUrl} from '@/variables';

export default {
  data: () => ({
    apiUrl: apiUrl,
    temperature: '',
    icon: '',
  }),
  name: 'object_Weather',
  mixins: [pageLoaded_child],
  methods: {
    fetchWeather() {
      axios
          .get(
              'https://api.openweathermap.org/data/2.5/forecast/daily?APPID=' +
              this.data.apiKey +
              '&units=metric&q=' +
              this.data.location +
              '&cnt=4'
          )
          .then((response) => {
            this.temperature = response.data.list[this.stepsMade].temp.day
                .toString()
                .split('.')[0]

              this.icon = response.data.list[this.stepsMade].weather[0].icon
          });
    },
  },
  created() {
    this.fetchWeather();
  },
};
</script>

<style lang="scss" scoped>
.temp {
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
}
</style>
