<template>
    <section aria-label="object-course">
        <div class="table-grid row">
            <div class="col">
                <icon
                    :state="item.metadata[0].value"
                    :icons="item.element.Status"
                />
            </div>

          <div class="col" v-html="data.name.includes('Kurzplatz OPEN GOLF') ? 'Kurzplatz <br> OPEN GOLF' : data.name" />

            <div class="col">
                <div>
                    <a
                        v-if="data.link"
                        :href="data.link.path"
                        style="text-decoration: none"
                        target="_blank"
                    >
                        <v-icon> mdi-information </v-icon>
                    </a>
                    <state :state="item.metadata[0].value" :type="data.name" />
                    <start
                        v-if="item.metadata[1].value"
                        :start="item.metadata[1].value"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import pageLoaded_child from '@/mixins/pageLoaded/child';
import icon from '@/components/ui/icon';
import State from '@/components/ui/state';
import Start from '@/components/ui/start';

export default {
    name: 'object_Course',
    mixins: [pageLoaded_child],
    components: {
        Start,
        State,
        icon,
    },
};
</script>
<style lang="scss">
.itemRow section span i {
  margin-left: 0 !important;
}

</style>
