<template>
    <section aria-label="object-track">
        <div class="table-grid row">
            <div class="col">
                <icon
                    :state="item.metadata[0].value"
                    :icons="data.parent.Status"
                ></icon>
            </div>
            <div class="col">
                {{ data.parent.name }}
                <span class="f500">{{ data.name }}</span>
            </div>
            <div class="col">
                <div>
                    <a
                        v-if="data.parent.link"
                        :href="data.parent.link.path"
                        style="text-decoration: none"
                        target="_blank"
                    >
                        <v-icon> mdi-information </v-icon>
                    </a>
                    <state :state="item.metadata[0].value" />

                    <!--                <div class="spaceholder-for-icon" />-->
                    <start
                        v-if="item.metadata[1].value"
                        :start="item.metadata[1].value"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import pageLoaded_child from '@/mixins/pageLoaded/child';
import icon from '@/components/ui/icon';
import Start from '@/components/ui/start';
import State from '@/components/ui/state';

export default {
    name: 'object_Track',
    components: { icon, State, Start },
    mixins: [pageLoaded_child],
};
</script>
