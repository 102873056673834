<template>
  <Page></Page>
</template>

<script>
import Page from "@/components/Page"

export default {

 components: { Page }
}
</script>

<style lang="scss" scoped>

</style>
