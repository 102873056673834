<template>
  <section aria-label="ui-state">

    <div v-if="type === 'Carts'">
      <span v-if="state === 'open'" class="btn-open">Erlaubt</span>
      <span v-else class="btn-close">Nicht erlaubt</span>
    </div>
    <div v-else-if="type === 'Trolleys'">
      <span v-if="state === 'open'" class="btn-open">Geöffnet</span>
      <span v-else-if="state === 'closed'" class="btn-close">Geschlossen</span>
      <span v-else class="btn-close">Keine e-Trolleys</span>
    </div>
    <div v-else>
      <span v-if="state === 'open'" class="btn-open">Geöffnet</span>
      <span v-else class="btn-close">Geschlossen</span>
    </div>
  </section>
</template>

<script>
export default {
    name: 'state',
    props: {
        state: String,
        type: String,
    },
};
</script>
