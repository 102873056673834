<template>
  <v-app>
    <section aria-label="page-content">
      <!--    <v-alert dense color="#ff0000" class="ma-2 text-center" style="border-radius: 50px; color: white">{{ alertMessage }}</v-alert>-->

      <div class="d-flex justify-space-between">
        <h2 v-html="date"/>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="loading || stepsMade === 0" class="mr-1 navi-btn" elevation="0" x-small
                     @click="getDay(-1)"
                     v-bind="attrs"
                     v-on="on">
                <v-icon x-small>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>{{ previousDate }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="loading || stepsMade > 2" class="mr-1 navi-btn" elevation="0" x-small @click="getDay(1)"
                     v-bind="attrs"
                     v-on="on">
                <v-icon x-small>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>{{ nextDate }}</span>
          </v-tooltip>
        </div>
      </div>

      <template v-if="loading">
        <v-skeleton-loader
            type="text"
            v-for="n in 14"
            :key="n"
            style="height: 2.5rem"
        />
      </template>
      <div v-else>
        <div v-for="(item, k) in list.items" :key="item.id">
          <hr v-if="k > 0 && item.element.__typename !== 'object_PinPosition' && item.element.name !== 'Trolleys'"/>
          <div class="itemRow"
               v-if="item.element.__typename !== 'object_PinPosition' && item.element.name !== 'Trolleys'">
            <component
                :data="item.element"
                :item="item"
                :is="item.element.__typename"
            />
          </div>
        </div>
        <div v-for="item in list.infoItems" :key="item.id">
          <hr/>
          <div class="itemRow">
            <component
                :data="item.element"
                :is="item.element.__typename"
                :stepsMade="stepsMade"
            />
          </div>
        </div>
      </div>
    </section>
  </v-app>
</template>

<script>

import DayQuery from '@/graphql/queries/day.graphql'
import object_Course from '@/components/items/object_Course.vue'
import object_Track from '@/components/items/object_Track'
import object_Location from '@/components/items/object_Location.vue'
import object_InfoItem from '@/components/items/object_InfoItem'
import object_Weather from '@/components/items/object_Weather'
import object_PinPosition from '@/components/items/object_PinPosition'
import object_Map from '@/components/items/object_Map'
import State from '@/components/ui/state'
import Start from '@/components/ui/start'
import icon from '@/components/ui/icon'

export default {
  name: 'Page',
  data: () => ({
    dialog: false,
    loading: false,
    day: '',
    list: '',
    date: '',
    alertMessage: '',
    previousDate: '',
    nextDate: '',
    selectedDate: '',
    stepsMade: 0
  }),
  components: {
    State,
    Start,
    icon,
    object_Course,
    object_Track,
    object_Location,
    object_InfoItem,
    object_Weather,
    object_Map,
    object_PinPosition
  },
  mounted() {
    this.load()
  },
  filters: {
    getGermanDate: function (date) {
      if (date) {
        let parsedDate = new Date(date)

        let year = parsedDate.getFullYear()
        let month = `${parsedDate.getMonth() + 1}`.padStart(2, "0")
        let day = `${parsedDate.getDate()}`.padStart(2, "0")

        return `${day}.${month}.${year}`
      }
    }
  },
  methods: {
    sendRequest() {
      this.$apollo
          .query({
            query: DayQuery,
            variables: {
              day: this.day,
            },
            fetchPolicy: 'no-cache',
          })
          .then((response) => {
            this.list = response.data.getDay
            this.loading = false
          })
    },
    getDay(plusOrMinus) {
      this.loading = true

      if (plusOrMinus === 1) {
        this.stepsMade++
        //next date
        var nextDate1 = new Date(this.selectedDate)
        nextDate1.setDate(nextDate1.getDate() + 2)

        this.nextDate = nextDate1.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
            this.$options.filters.getGermanDate(nextDate1)

        //previous date
        var previousDate1 = new Date(this.selectedDate)
        previousDate1.setDate(previousDate1.getDate())

        this.previousDate = previousDate1.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
            this.$options.filters.getGermanDate(previousDate1)
      } else {
        this.stepsMade--
        //previous date
        var previousDate = new Date(this.selectedDate)
        previousDate.setDate(previousDate.getDate() - 2)

        this.previousDate = previousDate.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
            this.$options.filters.getGermanDate(previousDate)

        //next date
        var nextDate = new Date(this.selectedDate)
        nextDate.setDate(nextDate.getDate())

        this.nextDate = nextDate.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
            this.$options.filters.getGermanDate(nextDate)
      }

      var selectedDate = new Date(this.selectedDate)
      selectedDate.setDate(selectedDate.getDate() + plusOrMinus)
      this.selectedDate = selectedDate

      this.date = selectedDate.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
          this.$options.filters.getGermanDate(selectedDate)

      this.day = '/days/' + selectedDate.getFullYear() + '/' +
          ('0' + (selectedDate.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + selectedDate.getDate()).slice(-2)

      this.sendRequest()

    },
    load() {
      //current date
      var currentDate = new Date()
      this.selectedDate = currentDate
      this.date = currentDate.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
          this.$options.filters.getGermanDate(currentDate)

      //next date
      var nextDate = new Date(currentDate)
      nextDate.setDate(nextDate.getDate() + 1)
      this.nextDate = nextDate.toLocaleDateString('de', {weekday: 'long'}) + ', ' +
          this.$options.filters.getGermanDate(nextDate)

      // displayed date
      this.day =
          '/days/' +
          currentDate.getFullYear() +
          '/' +
          ('0' + (currentDate.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + currentDate.getDate()).slice(-2)

      this.loading = true
      this.sendRequest()
    },
  },
}
</script>

<style lang="scss">
section[aria-label='page-content'] {
  max-width: 242px;

  .navi-btn {
    width: 10px !important;
    min-width: 10px !important;
    min-height: 25px !important;
    padding: 0;
  }


  hr {
    border: none;
    border-top: 1px solid $lightgray;
  }

  h2 {
    font-weight: 400;
    font-size: 18px;
    color: $primary;
    margin-bottom: 20px;
  }

  a[href^="tel:"] {
    color: $textgray;
    text-decoration: none;
  }

  .itemRow {
    min-height: 2.375rem;
    margin-top: 0 !important;

    .row:before {
      display: none;
    }

    .row:after {
      display: none;
    }

    & > section {
      .table-grid {
        min-height: 2.375rem;
        padding: 0.063rem 0;
        margin: 0 !important;
        display: grid;
        grid-template-columns: 2rem auto 6.375rem;
        width: 100%;

        .col {
          position: relative;
          padding: 0 !important;
          vertical-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;

          section {
            display: inline-block;

            &[aria-label='ui-icon'] {
              height: 100%;
              display: flex;
              justify-content: center;
            }
          }

          i {
            transform: translateY(-1px);
          }

          .spaceholder-for-icon {
            width: 1.063rem;
            display: inline-block;
          }

          i,
          .spaceholder-for-icon {
            margin-right: 0.25rem;
          }

          img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            max-height: 30px;
            width: auto;
          }
        }
      }
    }
  }
}

.centered-flex {
  height: 100%;
  display: flex !important;
  align-items: self-start;
}
</style>
