<template>
  <section aria-label="ui-start">
    {{ start }}
  </section>
</template>

<script>
export default {
  name: "start",
  props:{
    start: String
  },
  data () {
    return {

    }
  },
}
</script>
