<template>
  <section aria-label="object-pin-position">
    <div class="table-grid row">
      <div class="col">
        <img
            v-if="data.image"
            :src="apiUrl + data.image.fullpath"
            width="30"
        />
      </div>
      <div class="col">{{ data.name}}</div>
      <div class="col">
                <span class=" pin-position-info green-text">{{
                    data.pinPosition
                  }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import pageLoaded_child from '@/mixins/pageLoaded/child';
import {apiUrl} from "@/variables";

export default {
  name: 'object_PinPosition',
  mixins: [pageLoaded_child],
  data: () => ({
    apiUrl: apiUrl,
  }),
};
</script>

