<template>
    <section aria-label="ui-icon">
        <template v-for="icon in icons">
            <img
                v-if="state == icon.status"
                :key="icon.id"
                :src="apiUrl + icon.icon.fullpath"
                width="auto"
                height="100%"
            />
        </template>
    </section>
</template>

<script>
import { apiUrl } from '@/variables';

export default {
    name: 'icon',
    data: () => ({
        apiUrl: apiUrl,
    }),
    props: {
        state: String,
        icons: Array,
    },
};
</script>
