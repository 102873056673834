<template>
  <section aria-label="object-info-item">
    <div class="table-grid row">
      <div class="col">
        <img
            v-if="data.icon.fullpath"
            :src="apiUrl + data.icon.fullpath"
            width="30"
        />
      </div>
<!--      <div class="col" v-html="data.name"/>-->
      <div class="col" v-html="data.name.includes('Startzeiten Tee Times') ? 'Startzeiten <br> Tee Times' : data.name" />

      <div class="col centered-flex">
        <v-btn small text target="_blank" :href="data.link.path">
          <v-icon
              small
              color="#000000"
              style="margin-left: 0.5rem; transform: translateY(1px)"
          >
            mdi-open-in-new
          </v-icon>
          <span style="margin-right: 0.5rem">{{ data.link.text ? data.link.text : 'Buchung' }}</span>
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import pageLoaded_child from '@/mixins/pageLoaded/child';
import {apiUrl} from '@/variables';

export default {
  name: 'object_InfoItem',
  mixins: [pageLoaded_child],
  data: () => ({
    apiUrl: apiUrl,
  }),
};
</script>

<style lang="css" scoped>
.v-btn {
  text-transform: none !important;
}
</style>
