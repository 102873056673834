import Vue from "vue"
import PortalVue from 'portal-vue'
import vuetify from "./plugins/vuetify"
// import router from "./router"
import store from "./store"
import axios from "axios"
import VueAxios from "vue-axios"
import Vuex from "vuex"
import InstantSearch from 'vue-instantsearch'
import App from "./App"


import VueApollo from "vue-apollo"
import { ApolloClient } from "apollo-client"
import { ApolloLink } from "apollo-link"
import { createHttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"

import { apiUrl } from "./variables"

Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.use(PortalVue)
Vue.use(InstantSearch)

// custom directives
Vue.directive('visible', function(element, binding) {
  element.style.visibility = binding.value ? 'visible' : 'hidden'
})

Vue.config.productionTip = false

// import i18n from "./i18n"

// Cache implementation
const cache = new InMemoryCache()

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: apiUrl + "/pimcore-graphql-webservices/clubadmin",
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-API-Key": "4cf9ca8c06e0be44960d05153b872dde",
    },
  });
  return forward(operation)
});

const linkWithAuth = middlewareLink.concat(httpLink)

const userClient = new ApolloClient({
  link: linkWithAuth,
  cache,
  defaultOptions: {
    fetchPolicy: "no-cache",
  },
});

const apolloProvider = new VueApollo({
  defaultClient: userClient,
  clients: {
    userClient,
  },
})

Vue.use(VueApollo);

new Vue({
  vuetify,
  // router,
  store,
  apolloProvider,
  // i18n,
  render: (h) => h(App),
}).$mount("#app-monrepos")
