<template>
    <section aria-label="object_map">
        <v-row no-gutters class="table-grid">
            <v-col>
                <img
                    v-if="data.icon"
                    :src="apiUrl + data.icon.fullpath"
                    width="30"
                />
            </v-col>
            <v-col>
                {{ data.name }}
            </v-col>
            <v-col>
                <v-btn depressed x-small @click="showBigMap()">
                    <v-icon>mdi-map-marker-radius</v-icon>
                </v-btn>
            </v-col>

            <v-dialog
                transition="dialog-top-transition"
                max-width="900"
                v-model="dialog"
                persistent
            >
                <v-card>
                    <v-toolbar color="#4c4c4c" light
                        ><span class="white-text">Anfahrt</span></v-toolbar
                    >
                    <v-card-actions class="justify-end">
                        <v-btn fab depressed text @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="text-center">
                        <iframe
                            :src="data.location"
                            :width="
                                $vuetify.breakpoint.name === 'xs' ||
                                $vuetify.breakpoint.name === 'sm'
                                    ? $vuetify.breakpoint.width - 100
                                    : 850
                            "
                            height="600"
                            style="border: 0"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </section>
</template>

<script>
import pageLoaded_child from '@/mixins/pageLoaded/child';
import { apiUrl } from '@/variables';

export default {
    data: () => ({
        apiUrl: apiUrl,
        dialog: false,
    }),
    name: 'object_Map',
    mixins: [pageLoaded_child],
    methods: {
        showBigMap() {
            this.dialog = true;
        },
    },
};
</script>
